import { BrowserRouter, Route, Routes } from "react-router-dom"
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./app"
import "./app.scss"

const element = document.getElementById("app")
const root = ReactDOM.createRoot(element)

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/:lang?" element={<App />} />
    </Routes>
  </BrowserRouter>
)
