import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

export function useInfo() {
  const [params] = useSearchParams()
  return params.get("info") !== null
}

export function useNavi() {
  const navigate = useNavigate()
  const location = useLocation()
  return (pathname) => {
    navigate(
      {
        pathname: pathname,
        search: location.search,
      },
      { replace: true }
    )
  }
}

export function Block({ title, children }) {
  return (
    <div className="block">
      <h2>{title}</h2>
      <div className="content">{children}</div>
    </div>
  )
}

export function Contact({ icon, children }) {
  return (
    <p className="contact">
      {icon}
      {children}
    </p>
  )
}

export function Work({ company, title, start, end, children }) {
  return (
    <div className="work">
      <h3>{company}</h3>
      <p className="meta">
        {start} - {end}, {title}
      </p>
      {children}
    </div>
  )
}

export function Project({ name, desc, children }) {
  return (
    <div className="project">
      <h3>{name}</h3>
      <p className="meta">{desc}</p>
      {children}
    </div>
  )
}
