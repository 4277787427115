import { Block, Work, Project, Contact, useNavi, useInfo } from "./components"
import { LanguageIcon, MailIcon, GithubIcon, PhoneIcon } from "./icons"

export default function ResumeZH() {
  const navi = useNavi()
  const info = useInfo()
  return (
    <>
      <LanguageIcon className="lang" onClick={() => navi("/en")} />
      <h1>姚先生的个人简历</h1>
      <p className="subtitle">13 年从业经验，求职意向：架构师 / 全栈开发</p>
      {info && (
        <Block title="个人信息">
          <p>姓名：姚博</p>
          <p>学历：本科 / 计算机科学与技术</p>
          <p>从业经验：13年</p>
          <p>求职意向：架构师 / 全栈开发</p>
          <p>期望薪资：面议</p>
        </Block>
      )}
      <Block title="联系方式">
        {info && <Contact icon={<PhoneIcon />}>180-1279-5566</Contact>}
        <Contact icon={<MailIcon />}>abelyao@live.com</Contact>
        <Contact icon={<GithubIcon />}>
          <a href="https://github.com/yb" target="_blank">
            https://github.com/yb
          </a>
        </Contact>
      </Block>
      <Block title="工作技能">
        <p>主要技能：JavaScript, React, Vue, Node.js, Tailwind CSS</p>
        <p>常用框架：Next, Nuxt, Fastify, Express, Ant Design, Element UI</p>
        <p>其它技能：MongoDB, SQL, Redis, Nginx, Docker</p>
      </Block>
      <Block title="工作经验">
        <Work
          company="象帝先计算技术"
          title="软件工程师"
          start="2023年1月"
          end="至今"
        >
          <p>
            基于 React, Vue, Electron, X6, Echarts
            等前端框架，开发了与任务流程管理、数据分析、电路设计等芯片设计的相关软件。
          </p>
          <p>参与部分项目架构设计，管理前端开发任务，带领初级开发人员。</p>
        </Work>
        <Work
          company="硅康医药 / 腾迈医药"
          title="高级前端工程师"
          start="2020年5月"
          end="2022年7月"
        >
          <p>
            作为团队中第一位网站开发者，我与 R&D
            团队合作，主导设计开发了具有数据图表和 3D 分子显示的可视化 Web
            平台，它将所有科学计算工具和功能可视化，以另一种方式展示药物发现方法，帮助使用者轻松处理数据，并直观的在平台上查看计算结果报告。
          </p>
          <p>
            其中以 React 和 Ant Design 作为前端框架，使用 RDKit 和 NGL viewer
            绘制 2D/3D 分子结构，使用 Plotly.js 展示数据图表；以 Egg.js 和
            Sequelize ORM 作为后端框架。另外，使用 Next.js
            开发了一个计算集群资源使用率监控网站。
          </p>
        </Work>
        <Work company="独立开发者" title="全栈开发" start="2017年" end="2020年">
          <p>
            出于对代码的热爱，我于 2017
            年尝试创业并成为了一名全栈工程师。在此期间我完成了多个网站、微信公众号、小程序等应用。同时我也在
            Github 上分享了一些开源项目并取得了不错的反馈。
          </p>
          <p>
            我一直保持学习新的技能，从前端到后端，以丰富我的技能树，并从中享编程的快乐。
          </p>
        </Work>
        <Work
          company="甲秀网络"
          title="技术团队负责人"
          start="2016年2月"
          end="2017年1月"
        >
          <p>参与组建和管理开发团队</p>
          <p>与业务部门讨论需求并确定开发方案</p>
          <p>完成了一个 P2G 互联网金融平台</p>
        </Work>
        <Work
          company="金百汇信息科技"
          title="软件工程师"
          start="2012年4月"
          end="2015年2月"
        >
          <p>使用 ASP.NET 和 SQL Server 开发多商户电商平台</p>
          <p>
            使用 C# 和 SQL Server
            为汕头市体育局开发了赛事报名系统、运动员管理系统、官方网站等
          </p>
        </Work>
        <Work
          company="蓝凌科技"
          title="软件工程师"
          start="2011年4月"
          end="2012年3月"
        >
          <p>使用 ASP.NET 为中国移动某分公司开发市场营销平台</p>
          <p>使用 ASP.NET 为中国移动某分公司开发 OA 系统和通用业务组件</p>
        </Work>
      </Block>
      <Block title="个人项目">
        <Project
          name="Drug Design App"
          desc={
            <a href="https://drugdesign.app/" target="_blank">
              https://drugdesign.app/
            </a>
          }
        >
          <p>
            Drug Design App
            是一个提供了化合物属性计算或预测的网站。使用者通过网页提交分子列表，并等待计算，即可在线查看结果，包括
            2D/3D 的分子结构、分析图表等可视化数据。
          </p>
          <p>
            它基于 Next.js 开发，包括一个支持分布式的队列任务调度机制，采用
            Docker Compose 部署。
          </p>
        </Project>
        <Project
          name="Uptime Status"
          desc={
            <a href="https://github.com/yb/uptime-status" target="_blank">
              https://github.com/yb/uptime-status
            </a>
          }
        >
          <p>
            Uptime Status 是一个网站管理员用于展示网站在线状态的可视化面板。
          </p>
          <p>
            它基于 React 和 UptimeRobot 接口开发，是一个开源项目，在 Github
            收获了超过 1200+ star。
          </p>
        </Project>
        <Project name="H5Cafe" desc="微信公众号">
          <p>
            凭借一款 H5 小游戏，最高单日访问量超过 6,000,000 PV，500,000
            UV，100,000 人同时在线，以及 600 GB 流量，在半年内它为公众号带来了
            18 万粉丝。它仅使用了一台 4GB 内存的 Windows Server
            服务器，通过前后端分离技术，搭配 CDN 交付静态资源以减少服务器的 HTTP
            请求次数，并使用了 Redis 缓存部分数据以降低 SQL Server
            的负载。同时该项目还对接了微信支付实现了游戏充值功能。
          </p>
        </Project>
      </Block>
      <Block title="教育背景">
        <p>本科：华中科技大学 - 计算机科学与技术</p>
        <p>专科：汕头大学 - 软件技术</p>
      </Block>
      <Block title="总结">
        <p>
          我从初中开始钻研网站开发，现在我拥有丰富的互联网开发经验。除了代码本身，同时我也了解一些
          UI
          设计及用户体验。我运营过自己的网站和公众号，这些经历并没有影响我对技术的专注，反而使我在思考和分析产品需求时，能做出更好的方案。
        </p>
      </Block>
    </>
  )
}
