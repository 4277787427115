import { LanguageIcon, MailIcon, GithubIcon, PhoneIcon } from "./icons"
import { Block, Work, Project, Contact, useNavi, useInfo } from "./components"

export default function ResumeEN() {
  const navi = useNavi()
  const info = useInfo()
  return (
    <>
      <LanguageIcon className="lang" onClick={() => navi("/zh")} />
      <h1>Abel Yao</h1>
      <p className="subtitle">
        13 Years Work Experience, Career Objective: Architect / Full-Stack Engineer
      </p>
      <Block title="Contact">
        {info && <Contact icon={<PhoneIcon />}>180-1279-5566</Contact>}
        <Contact icon={<MailIcon />}>abelyao@live.com</Contact>
        <Contact icon={<GithubIcon />}>
          <a href="https://github.com/yb" target="_blank">
            https://github.com/yb
          </a>
        </Contact>
      </Block>
      <Block title="Skills">
        <p>Excellence on JavaScript, React, Vue, Node.js, Tailwind CSS</p>
        <p>Proficient with Next, Nuxt, Fastify, Express, Ant Design, Element UI</p>
        <p>Experienced on MongoDB, SQL, Redis, Nginx, Docker</p>
      </Block>
      <Block title="Work Experience">
        <Work
          company="XDXCT"
          title="Software engineer"
          start="2023.1"
          end="Now"
        >
          <p>
            Developed multiple systems related to chip design including task
            flow management and data analysis, based on React, Vue, Electron, X6-flow
            and Echarts, etc.
          </p>
          <p>
            Participate in project architecture design, manage front-end
            development works, and lead junior developers.
          </p>
        </Work>
        <Work
          company="SiliconTX / TandemAI"
          title="Senior front-end engineer"
          start="2020.5"
          end="2022.7"
        >
          <p>
            As the first web developer in the team, I lead to design and develop
            a visualization platform with data graphs and 3D molecular display
            in close collaboration with the R&D team, which is a pure original
            web-based platform to visualize all the deep scientific computation
            tools and features, to use another presenting way to show the drug
            discovery methods, help end-user to easily handle all the functions
            and tools and read the result report on the platform directly.
          </p>
          <p>
            React and Ant Design in the front-end part and RDKit and NGL viewer
            in 2D/3D molecular display, Plotly.js in formatting scientific
            charts, Egg.js framework and Sequelize ORM library in the back-end
            part. Additionally, a computing cluster monitor was developed using
            Next.js.
          </p>
          <p></p>
        </Work>
        <Work
          company="Independent developer"
          title="Full stack"
          start="2017"
          end="2020"
        >
          <p>
            Because of my passion for programming, I tried startup in 2017 and
            became a full stack engineer. During this period, I developed
            several websites, WeChat official accounts, and WeChat mini program.
            I shared some open-source projects on Github and achieved
            satisfactory feedback. I have kept learning new skills, in both
            front-end and back-end, thus perfecting my skill tree, and enjoying
            programming from it.
          </p>
        </Work>
        <Work
          company="Jiaxiu Network"
          title="Technical team leader"
          start="2016.2"
          end="2017.1"
        >
          <p>Formed and managed the development team.</p>
          <p>
            Discussed the requirements with the business department and
            determined the development plan.
          </p>
          <p>Delivered a P2G Internet financial platform.</p>
        </Work>
        <Work
          company="Jinbaihui Information"
          title="Software engineer"
          start="2012.4"
          end="2015.2"
        >
          <p>
            Developed a multi-merchant e-commerce platform using ASP.NET and SQL
            Server.
          </p>
          <p>
            Developed the event registration system, player management system
            and official website for Shantou Sports Bureau using C# and SQL
            Server.
          </p>
        </Work>
        <Work
          company="Landray Technology"
          title="Software engineer"
          start="2011.4"
          end="2012.3"
        >
          <p>
            Developed the marketing platform for China Mobile using ASP.NET.
          </p>
          <p>
            Developed the OA system and components for China Mobile using
            ASP.NET.
          </p>
        </Work>
      </Block>
      <Block title="Personal Projects">
        <Project
          name="Drug Design App"
          desc={
            <a href="https://drugdesign.app/" target="_blank">
              https://drugdesign.app/
            </a>
          }
        >
          <p>
            Drug Design App is a website that provides calculation or prediction
            of compound properties. Users submit a molecule list through the web
            page and wait for calculation, then the results can be viewed
            online, including 2D/3D molecular structures, analysis charts and
            other visual data.
          </p>
          <p>
            It is developed based on Next.js, includes a queue task scheduling
            mechanism that supports distributed deployment, and deployed using
            Docker Compose.
          </p>
        </Project>
        <Project
          name="Uptime Status"
          desc={
            <a href="https://github.com/yb/uptime-status" target="_blank">
              https://github.com/yb/uptime-status
            </a>
          }
        >
          <p>
            Uptime Status is an uptime dashboard develop using React and based
            on UptimeRobot API. It is an open-source project to visualize online
            status of webmasters, which has gained 1200+ stars recognized on
            Github.
          </p>
        </Project>
        <Project name="H5Cafe" desc="WeChat official account">
          <p>
            Over 6,000,000 page views, 500,000 unique visits, 100,000 online
            visitors, and 600 gigabytes of website traffic, in a single day.
            This is a mobile web game I individually developed, which has gained
            180,000 followers within 6 months. The server side only uses one
            Windows Server with 4GB memory. Benefiting from the separation of
            the front-end and back-end, I deliver static resources through CDN
            to reduce the HTTP requests to the server. Also, I use Redis to
            cache some data to reduce the SQL Server load. This project includes
            online payments.
          </p>
        </Project>
      </Block>
      <Block title="Education">
        <p>
          Bachelor degree in Computer Science and Technology, Huazhong
          University of Science and Technology
        </p>
        <p>College degree in Software Technology, Shantou University</p>
      </Block>
      <Block title="Summary">
        <p>
          I have devoted myself to website development since junior high school.
          Until now, I am equipped with rich experience in Internet development.
          Besides programming, I am also skilled in UI design and user
          experience. I have operated my own website and WeChat official
          account. These experiences have not affected my focus on development
          but triggering the in-depth analysis and consideration of product
          requirements when to make the best solution.
        </p>
      </Block>
    </>
  )
}
