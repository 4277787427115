import { Navigate, useParams } from "react-router-dom"
import ResumeEN from "./resume-en"
import ResumeZH from "./resume-zh"

export default function App() {
  const { lang } = useParams()
  if (lang === undefined) return <Navigate to="/zh" />
  if (lang === "zh") return <ResumeZH />
  if (lang === "en") return <ResumeEN />
}
